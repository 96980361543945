// Here you can add other styles
@import url('https://fonts.googleapis.com/css2?family=Caudex:ital,wght@0,400;0,700;1,400;1,700&family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

@mixin flex-grid-items($cols) {
  display: flex;
  flex-wrap: wrap;

  >* {
    $item-width: 100% / $cols;
    width: $item-width;
  }
}

body {
  font-family: "Lato", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Caudex', sans-serif;
}

a:hover {
  color: $color1;
}

p {
  padding-bottom: 15px;
}

p:last-child {
  padding-bottom: 0px;
}

.header-nav .nav-link {
  font-family: 'Caudex', sans-serif;
  color: $color6;
}

.sidebar-brand {
  background-color: $color1;
}

.sidebar-nav {
  background-color: $color3;
}

.sidebar-nav .nav-link {
  background-color: $color2;
}

.sidebar-nav .nav-link:hover,
.sidebar-nav .nav-link.active {
  background-color: $color4;
}

.sidebar-toggler,
.sidebar-toggler:hover {
  background-color: $color1;
}


.bg-light {
  background-color: $white-color !important;
}

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}

.pagination>.active>a {
  background-color: $color3;
  border-color: $color3;
  color: $white-color;
}

.pagination>li>a {
  border: 0px solid $color3;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
  background-color: $color3;
  border-color: $color3;
  outline: none;
}

.pagination>li>a,
.pagination>li>span {
  color: $color3;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
  border-radius: unset
}

.gradient-custom {
  background: -webkit-linear-gradient(left, $color3, $color4);
}

.card-custom {
  border-bottom-left-radius: 10% 50%;
  border-top-left-radius: 10% 50%;
  background-color: $white-color ;
}

.media-image {
  display: flex;
  border: 2px solid $color4;
  width: 99px;
  height: 99px;
  margin: 2px;
  align-items: center;
}

.media-image:hover,
.media-image.active {
  border: 2px solid $blue-color;
}

.media-image img {
  max-width: 100%;
  height: auto;
}

.accordion-button {
  text-transform: capitalize;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: $white-color;
  background-color: $color4;
}

.cursor-grab:hover {
  cursor: grab;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.pointer-disable {
  pointer-events: none;
}

.relative {
  position: relative;
}

.small-action {
  z-index: 9;
  position: absolute;
  right: 40px;
  top: 15px
}

.header {
  a.btn-primary {
    background-color: $color1;
    font-size: $size-0;

    &:hover {
      background-color: $color2;
    }

    &:focus {
      background-color: $color1;
    }

    &:active {
      background-color: $color1;
    }

    @include media-breakpoint-up(md) {
      font-size: $size-1;
    }
  }

  .header-nav .nav-item .nav-link {
    font-size: $size-00;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;

    &:hover {
      border-top-color: $color2;
      border-bottom-color: $color1;
      background-color: $color4;
    }
  }
}

.footer {
  background-color: $color5;

  .address-section {
    background-color: $color3;
    color: $color1;
    font-size: $size-1;

    & h2 {
      font-size: $size-5;
    }

    & h3 {
      font-size: $size-2;
    }
  }

  .copyright-section {
    background-color: $color2;
    color: $color3;
  }
}

.article {
  font-size: $size-1;

  & h2 {
    font-size: $size-3;
    color: $color1;
  }

  & ul li {
    list-style: outside;
    padding-bottom: 10px;
    margin-left: 20px;
  }

  & ul li::marker {
    color: $color1;
  }

  & ol {
    counter-reset: count;
  }

  & ol li {
    list-style-type: none;
    position: relative;
    padding-bottom: 10px;
  }

  & ol li:before {
    counter-increment: count;
    content: counter(count)" ";
    margin-right: 0.5em;
    display: inline-block;
    padding: 0 8px;
    border-radius: 50%;
    color: $white-color;
    background: $color1;
  }
}

.banner {
  font-family: 'Caudex', sans-serif;
  font-size: 20px;
  color: $color1;

  & h1 {
    font-size: 36px;
  }

  & ul {
    display: inline-block;
  }

  & ul li {
    list-style-position: outside;
    list-style-type: disclosure-closed;
    padding-bottom: 10px;
    text-align: left;
    margin-left: 20px;
  }

  & ul li::marker {
    font-size: $size-1;
  }
}

.blogroll {
  & h3 {
    font-size: $size-5;
    color: $color1;
  }

  & .subheading {
    color: $color1;
    font-size: $size-1;
  }

  & h2 {
    font-size: $size-2;
  }

  & h2 a {
    color: $color2;

    & :hover {
      color: $color2;
    }
  }
}

.recentpost {
  & h3 {
    font-size: $size-5;
    color: $color1;
  }

  & .subheading {
    color: $color1;
    font-size: $size-1;
  }

  & h2 {
    font-size: $size-2;
  }

  & h2 a {
    color: $color2;

    & :hover {
      color: $color2;
    }
  }
}

.heading {
  font-size: $size-1;

  & h3 {
    color: $color1;
    font-size: $size-5;
  }
  & h4 {
    color: $color1;
    font-size: $size-2;
  }

  & ul {
    display: inline-block;
  }

  & ul li {
    list-style-position: outside;
    list-style-type: disclosure-closed;
    padding-bottom: 10px;
    text-align: left;
    color: $color1;
    margin-left: 20px;
  }

  & ul li::marker {
    color: $color1;
    font-size: $size-1;
  }
}

a.bg-custom {
  background-color: $color1 !important;

  & :hover {
    background-color: $color2 !important;
  }
}

.bg-custom {
  background-color: $color4;
  font-size: $size-1;
  color: $black;

  & a {
    color: $color2;
    font-size: $size-2;

    & :hover {
      color: $color2;
    }
  }

  & h2 {
    font-size: $size-3;
  }

  & h2 a {
    color: $color2;
    font-size: $size-3;

    & :hover {
      color: $color2;
    }
  }
}

.border-top-custom {
  border-top-color: $color4;
}

.border-left-custom {
  border-left-color: $color4;
}

.border-bottom-custom {
  border-bottom-color: $color4;
}

.border-right-custom {
  border-right-color: $color4;
}

.bg-custom-dark {
  background-color: $color2;
  font-size: $size-1;
  color: $black;

  & a {
    color: $color2;
    font-size: $size-2;

    & :hover {
      color: $color2;
    }
  }

  & h2 {
    font-size: $size-3;
  }

  & h2 a {
    color: $color2;
    font-size: $size-3;

    & :hover {
      color: $color2;
    }
  }
}

.border-top-dark {
  border-top-color: $color2;
}

.border-left-dark {
  border-left-color: $color2;
}

.border-bottom-dark {
  border-bottom-color: $color2;
}

.border-right-dark {
  border-right-color: $color2;
}

figure.image {
  justify-content: center;
  display: flex;
}

figure.image.image-style-side {
  float: right;
  margin: 0px 0px 20px 20px;
}